.rate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .rate-message {
    font-size: 0.8em;
  }

  input {
    display: none;
  }

  .rate-icon {
    width: 0.7em;
    height: 0.7em;
    cursor: pointer;
  }
}
