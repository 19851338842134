// .header {
//   margin-bottom: 3rem;

//   .header-container {
//     height: 50px;

//     .item-modifier {
//       padding: 0 !important;
//     }
//   }
// }

@use "../../color-palette";

.header-container {
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid color-palette.$complementary-color;
  margin-bottom: 20px; //using pixel to match gap between profile-card
  position: relative;

  .header-logo-searchbox-container {
    width: 80%;
    display: flex;
    align-items: center;

    .header-searchbox {
      width: 100%;
    }
  }

  .header-links-container {
    display: flex;
    align-items: center;
  }

  .user-info-dropdown {
    transform: translateY(100%);
  }
}
