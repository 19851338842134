@use "../../color-palette";

.chat-input {
  height: 50px;
  padding: 0.714em;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 100%;
    border: none;
    outline: none;
    color: gray;
    font-size: 1.2em;

    &::placeholder {
      color: darkgray;
    }
  }

  .send {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      height: 2em;
      cursor: pointer;
    }

    button {
      border: none;
      border-radius: 10px;
      padding: 0.714em 1em;
      color: white;
      background-color: color-palette.$primary-color;
      cursor: pointer;
    }
  }
}
