.login-page-title {
  color: gray;
  text-align: center;
  margin: 0 !important;
}

.to-register-page {
  color: gray;
  text-align: center;
  margin-bottom: 0.42rem;

  .to-register-page-link {
    color: blue;
  }
}

.to-forget-password-page {
  color: gray;
  text-align: center;

  .to-forget-password-page-link {
    color: blue;
  }
}
