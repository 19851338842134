.user-info-container {
  color: gray;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  .user-info-menu {
    margin-top: 0.357em;
  }

  .user-info-full {
    display: none;
  }

  @media screen and (min-width: 576px) {
    .user-info-menu {
      display: none;
    }

    .user-info-full {
      display: flex;
      align-items: center;

      .user-info {
        display: inline-block;
        max-width: 14.285em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
