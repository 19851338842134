@use "./color-palette";

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  margin: 0;
  padding: 0 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 576px) {
    padding: 0 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: gray;
}

a:hover {
  color: color-palette.$complementary-color;
}
