.chat {
  height: 75vh;
  display: flex;
  align-items: top;
  justify-content: center;

  .container {
    border: 1px solid lightgray;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
  }
}
