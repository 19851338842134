.searchbox-container {
  position: relative;

  .search-icon {
    position: absolute;
    top: 0.5em;
    left: 8px; //using px here is appropriate since we want the same position from the left.
  }

  .searchbox {
    // -webkit-appearance: none;
    width: 100%;
    max-width: 240px;
    outline: none;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    line-height: 2em;
    padding: 0 0 0 2em;
    color: gray;

    &::placeholder {
      color: darkgray;
    }
  }

  @media screen and (min-width: 576px) {
    .search-icon {
      left: 10px; //using px here is appropriate since we want the same position from the left.
    }

    .searchbox {
      padding: 0 0 0 2.142em;
    }
  }
}
