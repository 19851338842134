@use "../../color-palette";

.chat-message {
  display: flex;
  gap: 20px;
  margin-bottom: 2em;

  .chat-message-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: darkgray;

    img {
      width: 2.857em;
      height: 2.857em;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .chat-message-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: gray;

    p {
      background-color: white;
      border: 1px solid color-palette.$complementary-color;
      padding: 0.714em 1.428em;
      border-radius: 0 10px 10px 10px;
      max-width: 50ch;
      word-break: break-all;
      hyphens: auto;
    }

    img {
      max-width: 50%;
    }
  }

  &.owner {
    flex-direction: row-reverse;

    .chat-message-content {
      align-items: flex-end;

      p {
        background-color: color-palette.$complementary-color;
        color: white;
        border-radius: 10px 0 10px 10px;
      }
    }
  }
}
