@use "../../color-palette";

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-title {
    font-size: 2em;
    color: gray;
    margin: 0;
    text-align: center;
  }

  .usp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    border: 1px solid color-palette.$complementary-color;
    border-radius: 10px;
    width: 100%;
    max-width: 320px;
    height: 150px;

    .usp-title {
      font-size: 1.428em;
      text-align: center;
      color: gray;
      margin: 0 0 1.4rem;

      &:nth-child(2) {
        margin: 0.7rem 0;
      }
    }

    button {
      border: none;
      border-radius: 5px;
      padding: 0.714em 0;
      color: white;
      background-color: color-palette.$primary-color;
      width: 220px;
    }
  }
}
