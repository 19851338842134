@use "../../color-palette";

.chat-message-date {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5em;
  color: white;
  font-size: 0.8em;

  p {
    background-color: color-palette.$secondary-color;
    padding: 0.454em 0.909em;
    border-radius: 10px;
    max-width: max-content;
  }
}
