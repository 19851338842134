// @mixin mobile {
//   @media screen and (max-width: 480px) {
//     @content;
//   }
// }

// @mixin tablet {
//   @media screen and (min-width: 481px) and (max-width: 768px) {
//     @content;
//   }
// }

.profile-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  // @include mobile {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @include tablet {
  //   grid-template-columns: repeat(3, 1fr);
  // }
}

@media screen and (min-width: 576px) {
  .profile-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    gap: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .profile-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
