.chat-search {
  border-bottom: 1px solid lightgray;

  .chat-search-form {
    padding: 0.714em;

    input {
      background-color: transparent;
      border: none;
      outline: none;
      color: gray;
      width: 100%;

      &::placeholder {
        color: darkgray;
      }
    }
  }
}
