.back-to-chat-list {
  &:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .back-to-chat-list {
    display: none;
  }
}
