@use "../../color-palette";

.user-info-dropdown-container {
  background: white;
  position: absolute;
  top: 50px; //using px here is appropriate since we want the same position from the top.
  text-align: center;
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.95;
  z-index: 999;

  &.close {
    display: none;
  }

  .user-info-dropdown-list {
    list-style: none;
    padding-inline-start: 0;
    margin-block: 0;

    .user-info-dropdown-item {
      padding: 2.142em 0;
      cursor: pointer;
      color: gray;

      &:hover {
        color: color-palette.$complementary-color;
      }
    }

    .user-info {
      cursor: auto;
      font-weight: bold;
      max-width: 14.285em;
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: gray;
      }
    }

    @media screen and (min-width: 576px) {
      .user-info {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .user-info-dropdown-container {
    display: block;
    width: auto;
    height: auto;
    right: 0;
    text-align: left;
    border: 1px solid lightgrey;
    border-radius: 5px;

    .user-info-dropdown-list {
      .user-info-dropdown-item {
        padding: 0.714em;

        &.user-info-dropdown-close {
          display: none;
        }
      }
    }
  }
}
