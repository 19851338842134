.rating-container {
  display: flex;
  align-items: center;
  gap: 5px;

  .rating-icon {
    aspect-ratio: 1 / 1;
    width: 1em;
    color: #fdb813;
  }

  .rating-number {
    color: gray;
  }
}
