@use "../../color-palette";

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

.chat-navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: color-palette.$primary-color;
  height: 50px;
  padding: 0.714em;
  color: white;

  img {
    background-color: white;
    width: 1.714em;
    height: 1.714em;
    border-radius: 50%;
    object-fit: cover;
  }

  span {
    max-width: calc(100% - 1.714em - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
