@use "../../color-palette";

.chat-content {
  &.show-chat-content {
    width: 100%;
  }

  &.hide-chat-content {
    display: none;
  }

  .chat-content-info {
    height: 50px;
    background-color: color-palette.$secondary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.714em;
    color: white;

    .chat-content-info-back-to-chat-list-username {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: calc(100% - 7ch);

      .chat-content-info-username {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .chat-content-empty-message {
    color: gray;
    font-size: 1.428em;
    padding-top: 0.5em; // font-size for this element is set above.
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .chat-content {
    &.show-chat-content {
      display: block;
      width: 66.66%;
    }

    &.hide-chat-content {
      display: block;
      width: 66.66%;
    }
  }
}
