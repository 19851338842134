@use "../../color-palette";

.profile-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  border-radius: 5px;
  overflow: hidden;

  img {
    aspect-ratio: 1 / 1;
    width: 100%;
  }

  .profile-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0.714em;

    .profile-card-user-info {
      display: flex;
      flex-direction: column;

      .username {
        font-weight: bold;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .real-name {
        color: grey;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .area {
        display: flex;
        color: grey;
        margin-bottom: 0.5em;
        white-space: nowrap;

        .city {
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .state {
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .description-container {
        margin-bottom: 1em;

        .description {
          max-height: 6em;
          overflow: hidden;
          word-break: normal;
          hyphens: auto;
        }

        .additional-description {
          color: grey;

          .favourite-food {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
          }

          .hobby {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .ajak-makan-button {
      border: none;
      border-radius: 5px;
      padding: 0.714em 0;
      color: white;
      background-color: color-palette.$primary-color;
      cursor: pointer;
    }
  }
}

// .profile-card-container {
//   width: 200px;
//   height: 450px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   border: 1px solid lightgrey;
//   border-radius: 5px;
//   overflow: hidden;

//   img {
//     width: 200px;
//     height: 200px;
//     object-fit: cover;
//   }

//   .profile-card-content {
//     width: 200px;
//     padding: 0.714em;
//     display: flex;
//     flex-direction: column;

//     .username {
//       font-weight: bold;
//       margin: 0;
//       overflow: hidden;
//       text-overflow: ellipsis;
//     }

//     .real-name {
//       color: grey;
//       margin: 0;
//       overflow: hidden;
//       text-overflow: ellipsis;
//     }

//     .area {
//       display: flex;
//       color: grey;
//       margin-bottom: 0.5em;
//       white-space: nowrap;

//       .city {
//         max-width: 85px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }

//       .state {
//         max-width: 90px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//     }

//     .description {
//       height: 6.5rem;
//       margin-bottom: 1em;
//       word-break: normal;
//       hyphens: auto;
//       overflow: hidden;

//       .additional-description {
//         line-height: 1rem;

//         .favourite-food {
//           color: grey;
//           font-size: 0.8em;
//         }
//       }
//     }
//   }

//   .ajak-makan-button {
//     border: none;
//     border-radius: 5px;
//     padding: 0.714em 0;
//     color: white;
//     background-color: color-palette.$primary-color;
//     cursor: pointer;
//   }
// }
