@use "../../color-palette";

.user-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.714em;
  cursor: pointer;

  &:hover {
    background-color: color-palette.$complementary-color;

    .user-chat-content {
      color: white;

      .user-chat-info {
        p {
          color: white;
        }
      }
    }

    .user-chat-status {
      .user-chat-timestamp {
        color: white;
      }
    }
  }

  .user-chat-content {
    display: flex;
    align-items: center;
    gap: 10px;
    color: gray;
    width: calc(100% - 6ch);

    img {
      width: 3.571em;
      height: 3.571em;
      border-radius: 50%;
      object-fit: cover;
    }

    .user-chat-info {
      width: calc(100% - 3.571em - 10px);

      span {
        font-size: 1.2em;
        font-weight: bold;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        color: darkgray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .user-chat-status {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    .user-chat-timestamp {
      color: darkgray;
    }

    .unread {
      color: green;
      font-size: 3em;
    }

    .read {
      color: transparent;
      font-size: 3em;
    }
  }
}
