@use "../../color-palette";

@mixin slideLabel {
  color: black;
  font-size: 0.714em;
  top: -2em; // font-size for this element is set above.
}

.profile-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .profilePicture {
    width: 100%;
    max-width: 240px;
    object-fit: cover;
  }

  .group {
    position: relative;
    margin: 1.428em 0 0;
    width: 100%;
    max-width: 240px;

    &:first-of-type {
      margin: 2em 0 0;
    }

    &:last-of-type {
      margin: 1.428em 0 1.4rem;
    }

    .form-input {
      width: 100%;
      padding: 0.3em 0.357em;
      border: 1px solid gray;
      border-radius: 5px;
      color: gray;

      &:focus {
        outline: none;
      }

      &:focus ~ .form-input-label {
        @include slideLabel();
      }

      &.form-file {
        opacity: 0;
        padding: 0;
      }

      &.form-date {
        &.empty {
          color: transparent;
        }
      }
    }

    .form-input-label {
      position: absolute;
      left: 5px; // using pixel here is approriate since we always want the same distance regardless of the font-size.
      top: 0.214em;
      color: gray;
      pointer-events: none;
      transition: 300ms ease all;

      &.slide {
        @include slideLabel();
      }
    }

    .form-file-label {
      color: gray;
      position: absolute;
      left: 0;
      width: 100%;
      line-height: 1.15;
      padding: 0.3em 0.357em;
      border: 1px solid gray;
      border-radius: 5px;
      z-index: -1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.empty {
        color: transparent;
      }
    }
  }

  .form-button {
    width: 100%;
    max-width: 240px;
    border: none;
    border-radius: 5px;
    background-color: color-palette.$primary-color;
    color: white;
    padding: 0.714em 0;
    cursor: pointer;
  }
}
