.register-page-title {
  text-align: center;
  margin: 0 !important;
  color: gray;
}

.to-login-page {
  text-align: center;
  color: gray;

  .to-login-page-link {
    color: blue;
  }
}
