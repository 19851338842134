.logo-container {
  .logo-icon {
    margin: 0.357em 0.714em 0 0;
  }

  .logo-full {
    display: none;
  }

  .logo-default {
    font-weight: bold;
  }

  @media screen and (min-width: 576px) {
    .logo-full {
      display: block;
      margin: 0 1.428em 0 0;
      font-weight: bold;
      white-space: nowrap;
    }

    .logo-icon {
      display: none;
    }
  }
}
