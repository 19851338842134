.chat-icon-container {
  display: flex;
  position: relative;

  .chat-icon {
    margin: 0 0.714em;
  }

  .chat-notification {
    position: absolute;
    font-size: 3em;
    bottom: -2px; // using pixel here is approriate since we always want the same number regardless of the size of the icon.
    right: 3px; // using pixel here is approriate since we always want the same number regardless of the size of the icon.

    &.unread {
      color: green;
    }

    &.read {
      color: transparent;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
