.chat-sidebar {
  &.show-chat-sidebar {
    width: 100%;
  }

  &.hide-chat-sidebar {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .chat-sidebar {
    &.show-chat-sidebar {
      display: block;
      width: 33.33%;
      border-right: 1px solid lightgray;
    }

    &.hide-chat-sidebar {
      display: block;
      width: 33.33%;
      border-right: 1px solid lightgray;
    }
  }
}
